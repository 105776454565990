<template>
    <b-card style="background: rgba(115, 115, 141, 0.04); border-radius: 16px;">
        <b-row v-if="!isLoading">
            <b-col cols="12" lg="2" class="d-flex justify-content-center flex-md-column">
                <uploadImage :uploadImage="user.image" :isUpload="false" :text="user.name" />
            </b-col>
            <b-col cols="12" lg="10">
                <div class="d-flex justify-content-end">
                    <div class="change-detail" @click="$refs.userEdit.OpenModal()"><i class="fa fa-pencil" aria-hidden="true"></i> Change account details</div>
                </div>
                <div class="user-info">
                    <h3 class="user-name">{{ user.name }}</h3>
                    <div class="user-detail mr-3"><span style="color: #73738D;">Email:</span> {{ user.email }}</div>
                    <div class="user-detail mr-3"><span style="color: #73738D;">Phone number:</span> {{ user.phone?user.phone:'Not Provided' }}</div> 
                    <div class="user-detail mr-3 mt-2 d-flex align-items-center" ><span class="mr-2" style="height:15px;width:15px;border-radius: 50%;" :class="isEmailVerified?'bg-success':'bg-danger'"></span> <span >{{ isEmailVerified? 'Email verified' : 'Email not verified' }}</span></div> 
                </div>
                <div class="user-info mt-4 d-flex justify-content-between" v-if="getUserType=='web' || getUserType=='bc'">
                    <div class="user-detail mr-3"><span style="color: #73738D;">Password:</span> ************</div> 
                    <div class=" change-detail" @click="$refs.ChangePassword.OpenModal()">
                    <i class="fa fa-pencil" aria-hidden="true"></i> change password
                </div>
                </div>
            </b-col>
        </b-row>
        <!-- Skeleton -->
         <b-row v-else>
            <b-col cols="12" lg="2" class="d-flex justify-content-center flex-md-column">
                <uploadImage  :isUpload="false" :text="getUserData.name" />
            </b-col>
            <b-col cols="12" lg="10">
                <div class="d-flex w-100 justify-content-end">
                    <div class="change-detail d-flex"><i class="fa fa-pencil" aria-hidden="true"></i> <b-skeleton class="w-100 ml-2"></b-skeleton></div>
                    
                </div>
                <div class="user-info">
                    <h3 class="user-name d-flex"><b-skeleton class="w-100 ml-2"></b-skeleton></h3>
                    <div class="user-detail d-flex mr-3"><span style="color: #73738D;">Email:</span> <b-skeleton class="w-100 ml-2"></b-skeleton></div>
                    <div class="user-detail d-flex mr-3"><span style="color: #73738D;">Phone number:</span> <b-skeleton class="w-75 ml-2"></b-skeleton></div> 
                </div>
               
            </b-col>
        </b-row>
        <EditUserModalVue ref="userEdit" :user="user" @UpdateUserImageData="UpdateUserData" @UpdateAccountData="UpdateUserData"></EditUserModalVue>
        <ChangePassword ref="ChangePassword" :user="user" @UpdateAccountData="UpdateUserData"></ChangePassword>
        
    </b-card>
</template>

<script>
import {mapGetters} from "vuex";
import EditUserModalVue from './EditUserModal.vue'

import "vue-tel-input/dist/vue-tel-input.css";
import UploadImage from './UploadImage.vue'
import ChangePassword from './ChangePassword.vue'
export default{
   
    props:{
        user:Object,
        isLoading:Boolean
    },
    components:{
        UploadImage,
        EditUserModalVue,
        ChangePassword,

    },
    computed:{
        ...mapGetters( ["getNavbarData","getUserAllAccountData"]),
     getUserType(){
      return this.getNavbarData.userType
    },
    getUserData(){
return this.getNavbarData
    },
    isEmailVerified(){
        let {email_verified_at} = this.getUserAllAccountData.account
        return typeof email_verified_at == 'string' ? true : false
    }
    },
    methods:{
        UpdateUserData(){
            this.$emit('UpdateUserData')
        }
    },

}
</script>
<style scoped>
.user-name{
    font-size:22px;
    font-weight: 600;
}
.user-detail{
    font-size: 14px!important;
    
}
.change-detail{
    color: #73738D;
    font-size: 12px;
    font-weight: 500;
    transition: 0.3s ease all;
    cursor:pointer;
}
.change-detail:hover{
    color:#4d1b7e;
}
</style>
